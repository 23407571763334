import React, {
    useState,
    useCallback,
    useMemo,
    useEffect,
    useContext
} from "react";
import {
    Form,
    Row,
    Col,
    Button,
    Container
} from "react-bootstrap";
import LandingPageLayout from "../../layouts/landing-page-layout/LandingPageLayout";
import Input from '../../components/input/Input';
import Validator from "../../services/validator/Validator";
import { Link } from 'gatsby';
import UserContext from "../../components/user-context/UserContext";
import Authenticator from "../../services/Authenticator";
import { NotificationContext } from '../../components/notification/Notification';
import { handleNavigate } from "../../services/Navigate";
import moment from 'moment';

import './Login.scss';

const LOGIN_FIELDS = [
    {
        placeholder: 'Email Address',
        name: 'emailAddress',
        type: 'email',
        isRequired: true,
        validation: (value) => Validator.checkValidEmail(value)
    }, {
        placeholder: 'Password',
        name: 'password',
        type: 'password',
        isRequired: true,
        validation: (value) => Validator.checkValidPassword(value)
    },
];

const Login = () => {
    const {notify} = useContext(NotificationContext);
    const [fieldErrors, setFieldErrors] = useState({
        emailAddress: [], password: [],
        // rememberMe: []
    });
    const [fieldData, setFieldData] = useState({
        emailAddress: '', password: '',
        // rememberMe: ''
    });
    const [globalError, setGlobalError] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (userContext?.state === userContext?.STATES?.LOGGED_IN) {
            handleNavigate('/account');
        }
    }, [userContext]);

    const onChange = useCallback((evt) => {
        const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
        const currentInput = LOGIN_FIELDS.filter((field) => field.name === evt.target.name)[0];
        const currentInputErrors = evt.target.type !== 'checkbox' && currentInput?.validation(value);

        if (evt.target.type !== 'checkbox') {
            setFieldErrors((prevState) => ({
                ...prevState,
                [evt.target.name]: currentInputErrors
            }));
        }

        setFieldData((prevState) => ({
            ...prevState,
            [evt.target.name]: value
        }));
    }, []);

    const isButtonDisabled = useMemo(() => {
        const isError = Object.entries(fieldErrors).filter((error) => error[1].length !== 0) && Object.entries(fieldErrors).filter((error) => error[1]?.length !== 0);
        if (! isError.length) {
            const {emailAddress, password} = fieldData;

            return emailAddress && password ? true : false;
        }

        return false;
    }, [fieldData, fieldErrors]);

    const loginInputDOM = useMemo(() => {
        return LOGIN_FIELDS.map((field, index) => {
            const autoFocus = index === 0 ? {
                autoFocus: true
            } : {};
            return <Form.Group key={
                    field.name
                }
                className='mb-3'>
                <Input {...autoFocus}
                    value={
                        fieldData[field.name]
                    }
                    field={field}
                    onChange={onChange}
                    errors={
                        fieldErrors[field.name]
                    }
                    showErrorsOnFocusOnly={false}/>
            </Form.Group>;
        });
    }, [fieldData, fieldErrors, onChange]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoggingIn(true);
        try {
            await Authenticator.signIn(fieldData.emailAddress, fieldData.password);
            handleNavigate('/account');
        } catch (error) {
            const errorResponse = error?.response?.data?.error?.name;
            setIsLoggingIn(false);
            if (errorResponse === 'userNotFoundException' || errorResponse === 'notAuthorizedException') {
                setGlobalError('Incorrect username or password.');
            } else if (errorResponse === 'userNotConfirmedException') {
                setGlobalError('User is not confirmed. Please activate your account first!')
            } else if (errorResponse === 'blockUserError') {
                const lockTime = moment.duration(error?.response?.data?.error?.lockTime).humanize();
                setGlobalError(`Too many attempts. Please try again in ${lockTime}`)
            } else {
                setGlobalError(errorResponse)
            }
        };
    };

    const resendActivationLink = async (email) => {
        try {
            await Authenticator.resendSignUp(email);

            notify({message: 'We have sent you an email with the activation link.', type: 'success'})
        } catch (error) {
            setGlobalError(error.message);
        }
    }

    useEffect(() => {
        notify({message: globalError, type: 'critical'})
    }, [globalError]);

    const elementsDOM = useMemo(() => {
        if (userContext?.state === userContext?.STATES?.LOGGED_IN) {
            return;
        }

        return <Col xs={12}
            md={8}
            xl={6}
            className='mx-auto'>
            <h1 className='mb-5'>Login</h1>
            <Form className='w-100 formWrapper'>
                {loginInputDOM}
                <div className={
                    `forgotPasswordWrapper d-flex flex-row align-items-center ${
                        globalError === 'User is not confirmed. Please activate your account first!' ? 'justify-content-between' : 'justify-content-end'
                    } w-100 mt-4 mb-2`
                }>
                    {
                        globalError === 'User is not confirmed. Please activate your account first!' && <Link to=''
                            onClick={
                                () => {
                                    (async () => resendActivationLink(fieldData.emailAddress))()
                                }
                            }
                            className='blue'>Resend Activation Link</Link>
                    }

                    <Link to='/reset-password'
                        state={
                            {emailAddress: fieldData['emailAddress']}
                    }>Forgot password?</Link>
                </div>
                <Button variant="primary" type="submit" className='mt-5'
                    disabled={
                        ! isButtonDisabled || isLoggingIn
                    }
                    onClick={
                        (event) => {
                            (async () => await handleSubmit(event))()
                        }
                    }
                    style={
                        {padding: '1rem 4rem'}
                }>
                    {
                    isLoggingIn ? 'Processing...' : 'Login'
                } </Button>
            </Form>
        </Col>
    }, [
        loginInputDOM,
        onChange,
        isButtonDisabled,
        fieldData,
        userContext,
        isLoggingIn,
        globalError
    ]);

    return <LandingPageLayout classNames='landingPage login'
        onlyLogo={true}
        pageTitle='Login'>
        <Container>
            <Row> {elementsDOM} </Row>
        </Container>
    </LandingPageLayout>
};

export default Login;
